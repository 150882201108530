/*
START MAIN.JS
________________
*/
//REMOVE JSNOTE
(function(){
    document.getElementById('jsNote').remove();
})();

(function () {//PROVIDES MENUBUTTON FUNCTIONALITY
    var dropdownNav = document.getElementById('dropdownNav');

    function openMenu(dropdownNav) {
        TweenLite.fromTo(dropdownNav, 0.75, { xPercent: 150 }, { xPercent: 0, ease: Back.easeOut.config(1) });
    }

    function closeMenu(dropdownNav) {
        TweenLite.fromTo(dropdownNav, 0.75, { xPercent: 0 }, { xPercent: 150, ease: Back.easeIn.config(1), onComplete: function () { dropdownNav.style.display = 'none'; } });
    }

    document.getElementById('navButton').addEventListener('click', function () {
        if (dropdownNav.style.display === "none") {
            dropdownNav.style.display = 'block';
            openMenu(dropdownNav);
        }
    }, false);

    window.addEventListener('click', function (event) {
        if (dropdownNav.style.display === "block") {
            if (event.path) {
                for (var i = 0; i < event.path.length; i++) {
                    if (event.path[i].id === 'dropdownNav') {
                        return;
                    }
                }
            }
            closeMenu(dropdownNav);
        }
    }, true);

})();


//PROVIDES SHOWCASE FUNCTIONALITY
(function () { //SELF EXECUTING

    var hoverElement = document.querySelectorAll('.showcase .overlay');
    window.fadedSwitch = true;
    window.fadeDelay = null;

    function fadeIn(eventElement) {
        /*var title = eventElement.parentNode.querySelector('.title');
        var subtitle = eventElement.parentNode.querySelector('.subtitle');*/
        var backing = eventElement.parentNode.querySelector('.backing');
        var shadow = eventElement.parentNode.querySelector('.shadow');
        var wrapper = eventElement.parentNode;
        eventElement.TimeLine = new TimelineLite();
        eventElement.TimeLine.to(backing, 0.25, { opacity: 0 }, 0)
            .to(wrapper, 0.25, { boxShadow: '0px 0px 25px -5px white' }, 0)
            .to(shadow, 0.5, { opacity: 1, onComplete: function () { window.fadeDelay = setTimeout(function () { eventElement.fadedSwitch = false; window.fadedSwitch = false; }, 50); } }, 0);
    }//END fadeOverlaysIn

    function fadeOut(eventElement) {
        /*var title = eventElement.parentNode.querySelector('.title');
        var subtitle = eventElement.parentNode.querySelector('.subtitle');*/
        var backing = eventElement.parentNode.querySelector('.backing');
        var shadow = eventElement.parentNode.querySelector('.shadow');
        var wrapper = eventElement.parentNode;
        eventElement.TimeLine = new TimelineLite();
        eventElement.TimeLine.to(backing, 0.25, { opacity: 1 }, 0)
            .to(wrapper, 0.25, { boxShadow: '' }, 0)
            .to(shadow, 0.5, { opacity: '', onComplete: function () { eventElement.fadedSwitch = true; window.fadedSwitch = true; } }, 0);
    }//END fadeOverlaysOut

    function touchmove(event) {
   /*     var max = 10;
        var divider = 20;
        var lastCommand = this.lastScroll-event.touches[0].clientY;
        this.lastScroll3 = this.lastScroll2;
        this.lastScroll2 = this.lastScroll1;
        this.lastScroll1 = lastCommand;
        var direction = ((this.lastScroll1 + this.lastScroll2 + this.lastScroll3)/3)/divider;
        console.log(direction)
        if (direction > max){
            direction=max;
        }
        window.scrollBy(0, direction);
        this.lastScroll1 = 0;
        this.lastScroll2 = 0;
        this.lastScroll3 = 0;
     */  
    }

    function touchend() {
        this.removeEventListener('touchmove', touchmove, false);
        this.removeEventListener('touchend', touchend, false);
    }

    //MOUSEOVER    

    for (var i = 0; i < hoverElement.length; i++) {
        hoverElement[i].fadedSwitch = true;
        hoverElement[i].touchmoveOn = false;
        hoverElement[i].touchmoveDelay = null;

        hoverElement[i].addEventListener('mouseover', function (event) {
            fadeIn(this);
        }, false);//END MOUSEOVER LISTENER

        //MOUSEOUT
        hoverElement[i].addEventListener('mouseout', function (event) {
            fadeOut(this);
        }, false);//END MOUSEOUT LISTENER

        //TOUCHSCREENS
        hoverElement[i].addEventListener('touchstart', function (event) {
            if (this.fadedSwitch) {
                fadeIn(this);
                this.addEventListener('touchmove', touchmove, false);
                this.addEventListener('touchend', touchend, false);
            }
        }, false);//END TOUCHSCREENS

    }//END FOR

    //TOUCHSCREEN FADEOUT WHEN NOT FOCUSED    
    window.addEventListener('touchstart', function (event) {
        if (!window.fadedSwitch) {
            for (var i = 0; i < hoverElement.length; i++) {
                if (!hoverElement[i].fadedSwitch && window.fadeDelay !== null) {
                    fadeOut(hoverElement[i]);
                }
            }
        }
    }, false);

})();//END SELF EXECUTING ANONYMOUS FUNCTION